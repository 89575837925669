@use "../../theme/vars.scss";

.tabList {
  background-color: white;
}
.loadingBar {
  position: absolute;
  left: 0;
  right: 0;
}
.loading {
  border-color: var(--color-gray-light);
}

.endpointSelector {
  padding-top: 3px;
}

.executeSparqlButton {
  min-width: unset;
  .playIcon {
    height: 1.5rem;
  }
  &.executeQueryButton {
    padding: 6px 10px;
  }
  &.executeUpdateButton {
    padding: 4px 8px;
  }
}

.editorContainer {
  background-color: white;
  padding: 0;
  .editor {
    margin: 0;
    overflow: hidden;
    :global(.cm-tooltip) {
      z-index: 1052;
    }
    :global(.cm-gutters) {
      height: 100%;
    }
  }

  position: relative;
  .badge {
    position: absolute;
    top: 0.5rem;
    right: 1rem;
    :global(.MuiBadge-badge) {
      width: 20px;
      z-index: 1051;
    }
    .icon {
      justify-content: flex-end;
    }
  }
}

.tab {
  text-transform: none;
  &:global(.MuiTab-labelIcon.Mui-selected) {
    padding-right: 0;
  }
}

.ideWrapper {
  display: grid;
  // Editor Elements Divider Visualization
  grid-template-rows: auto auto auto 1fr;
  height: calc(100vh - #{vars.$navbar-height} - 48px - 1px);
  overflow: auto;
}

// "Hacks" the dragHandle when it goes outside of the hover
.dragging {
  cursor: ns-resize;
  > * {
    pointer-events: none;
  }
}

.largeResultsContainer {
  position: relative;
}

.dragContainer {
  position: relative;
}
.resizableEditor {
  height: inherit;
  :global(.cm-editor) {
    height: inherit;
  }
}

.autoResizeContainer {
  display: flex;
  justify-content: center;
}

.autoResize {
  position: absolute;
  bottom: 10px;

  .autoResizeNoBg {
    background-color: unset;
  }
  .autoResizeWhiteBg {
    background-color: #ffffff;
    opacity: 0.8;
  }
}

/** Saved query dialog */

.accounts {
  display: flex;
  flex-wrap: wrap;
}

.account {
  opacity: 0.8;
  cursor: pointer;

  &:hover {
    opacity: 1;
  }

  .accountName {
    text-align: center;
    word-break: break-all;
    word-wrap: break-word;
    max-width: 80px;
  }
}
