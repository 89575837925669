@use "../../../theme/vars.scss";
@use "../../../theme/zIndexes.scss";

.visualizationSelectorContainer {
  display: flex;
  align-items: center;
  height: 45px;
}
.disableTableWidth {
  width: fit-content;
}
.resultsContainer {
  position: relative;
  overflow: auto;
}
